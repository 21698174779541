import Request from '@/utils/request';

// 【招商线索】请求客户数量
export function getCount(data) {
    return Request('post', '/apg/sales/clue/clueList/count', data)
}

// 【招商线索】请求客户列表
export function getClueList(data) {
    return Request('post', '/apg/sales/clue/clueList', data)
}

// 【招商线索】请求线索详情
export function getClueInfo(clueId) {
    return Request('get', '/apg/sales/clue/clueEdit?clueId=' + clueId)
}

// 【招商线索】请求线索跟进详情
export function getTimelineDtoList(clueId) {
    return Request('get', '/apg/sales/clue/clueInfo?clueId=' + clueId)
}

// 【招商线索】请求部门-招商经理二级联动列表
export function getConsultantList(data) {
    return Request('post', '/apg/sales/clue/clueList/consultantUserList', data)
}

// 【招商线索】请求部门-招商经理二级联动列表
export function getClueChange(data) {
    return Request('post', '/apg/sales/clue/clueList/updClueStatus', data)
}

// 【招商线索】获取流失原因列表
export function getLoseList() {
    return Request('get', '/apg/sales/clue/clueEdit/getCodeLabel?codeType=LOSE_REASON')
}

// 【招商线索】园区总监和客户经理列表
export function getConsultantUserList() {
    return Request('post', '/apg/sales/clue/clueList/consultantUserList', {
        roleCode: [3010, 3011],
    })
}

// 【招商线索】来源渠道列表
export function getChannelList() {
    return Request('get', '/apg/sales/clue/clueEdit/getCodeLabel?codeType=CHANNEL_TYPE')
}

// 【招商线索】合伙人及二级渠道
export function getPartnerList(channelType) {
    return Request('get', '/apg/sales/clue/clueEdit/listPartner?channelType=' + channelType)
}

// 【招商线索】行业列表
export function getIndustryList() {
    return Request('get', '/apg/sales/clue/clueEdit/industryList')
}

// 【招商线索】意向园区
export function getProjectList(deptId) {
    return Request('get', '/apg/sales/clue/intentionProject?deptId=' + deptId)
}

// 【招商线索】意向区域
export function getBusinessList() {
    return Request('get', '/apg/sales/clue/areaSelect')
}

// 【招商线索】需求面积
export function getAreaList() {
    return Request('get', '/apg/sales/clue/clueEdit/getCodeLabel?codeType=REQUIRED_AREA_RANGE')
}

// 【招商线索】保存新建或编辑线索
export function getClueUpdate(data) {
    return Request('post', '/apg/sales/clue/clueEdit/save', data)
}


// 老接口收纳
// 获取客户详情
export function detail(data) {
    return Request('post', '/apd/sales/customer/customerInfo/detail', data)
}
// 获取园区列表
export function projectList() {
    return Request('post', '/apd/sales/customer/selectBox/projectList')
}
//获取房间
export function clueEdit(clueId) {
    const data = {
        clueId
    }
    return Request('post', '/api/clueEdit/index', data)
}
//获取流程列表
export function flowList(pdType, unitId) {
    const data = {
        pdType,
        unitId
    }
    return Request('post', '/api/common/flowList', data)
}
//预定单详情
export function reserveInfo(preorderId) {
    return Request('get', '/api/work/preorderInfo/' + preorderId)
}
//api@签约回显信息
export function newContractEdit(clueId) {
    return Request('get', '/apg/finance/contract/newContractEdit?contractAccess=' + 1 + '&clueId=' + clueId + '&mobileOrPc=' + true)
}
// api@获取客户经理
export function consultantUserList() {
    const data = {
        roleCode: [3011]
    }
    return Request('post', '/apg/contract/consultantUserList', data)
}
// api@获取园区列表
export function getProject() {
    const data = {}
    return Request('post', '/apg/contract/getProject', data)
}
// api@根据园区id获取房源列表
export function projectSelect(projectId) {
    return Request('post', '/apg/contract/resourceSelect?projectId=' + projectId)
}
// api@生成自定义周期
export function getDiyPayCycle(data) {
    return Request('post', '/apg/finance/contract/newContractEdit/payCycle', data)
}
// api@生成费用明细
export function newCalculateFee(data) {
    return Request('post', '/apg/finance/contract/newContractEdit/newCalculateFee', data)
}
// api@保存并审批
export function newSave(data) {
    return Request('post', '/apg/finance/contract/newContractEdit/newSave', data)
}
// api@编辑后保存并审批
export function newSave2(data) {
    return Request('post', '/apg/finance/contract/newContractEdit/newUpdate', data)
}

// 获取线索详情
export function getNewClueInfo(data) {
    return Request('get', '/apg/sales/clue/clueInfo?clueId=' + data.clueId)
}