<template>
  <div class="clue-info">
    <Back title="线索详情" back="/clue/clueList"></Back>
    <main>
      <div class="main">
        <section class="text-item">
          <h1 class="van-hairline--bottom">客户信息</h1>
          <div class="text-content">
            <p>客户名：{{ clueInfo.tenantName || "-" }}</p>
            <p>联系人：{{ clueInfo.userName || "-" }}</p>
            <p>联系电话：<span class="theme-color" @click="tel(clueInfo.mobile)">{{ clueInfo.mobile || "-" }}</span>
            </p>
            <p>招商经理：{{ clueInfo.consultantName || "-" }}</p>
            <p>来源渠道：{{ clueInfo.channelTypeName || "-" }}</p>
            <p>客户等级：{{ turnoverOddsObj[clueInfo.turnoverOdds] || "-" }}</p>
            <p>客户行业：{{ clueInfo.industryName || "-" }}</p>
            <p>客户状态：<span :style="{ 'color': clueStatusColorObj[clueInfo.clueStatus] }">{{
                clueStatusObj[clueInfo.clueStatus] || "-"
            }}</span></p>
          </div>
        </section>
        <section class="text-item">
          <h1 class="van-hairline--bottom">需求信息</h1>
          <div class="text-content">
            <p>意向园区：{{ clueInfo.projectName || "-" }}</p>
            <p>需求面积：{{ clueInfo.requiredAreaRangeName || "-" }}</p>
            <p>备注信息：{{ clueInfo.remarkContent || "-" }}</p>
          </div>
        </section>
        <section class="text-item">
          <h1 class="van-hairline--bottom">跟进记录</h1>
          <div>
            <van-steps class="follow-list" direction="vertical" :active="0" active-color="#969799">
              <van-step v-for="item in timelineDtoList" :key="item.recordTime">
                <h3 class="follow-item">
                  <span>{{ changeDate(item.recordTime, 1) }}</span>
                  <span :style="{ 'color': clueStatusColorObj[item.traceStatus] }">{{ clueStatusObj[item.traceStatus]
                  }}</span>
                </h3>
                <p>{{ item.content || "--" }}</p>
              </van-step>
            </van-steps>
          </div>
        </section>
      </div>
      <div class="foot van-hairline--top">
        <van-button v-if="clueInfo.clueStatus != 3" class="size orange" @click="onChange">转交</van-button>
        <van-button v-if="clueInfo.clueStatus != 3" class="size blue" @click="onEdit">编辑</van-button>
        <van-button class="size green" @click="onFollow">跟进</van-button>
      </div>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import {
  turnoverOddsObj,
  clueStatusObj,
  clueStatusColorObj,
} from "@/db/clue";
import {
  getClueInfo,
  getTimelineDtoList,
} from "@/api/clue/clue"
import {
  changeDate,
} from "@/utils/utils"
export default {
  components: { Back },
  data() {
    return {
      turnoverOddsObj,
      clueStatusObj,
      clueStatusColorObj,
      changeDate,
      clueId: this.$route.query.clueId,
      clueInfo: "",
      timelineDtoList: "",
      changeShow: false
    }
  },
  created() {
    this.getClueInfo();
    this.getTimelineDtoList();
  },
  methods: {
    // 【监听】拨打电话
    tel(tel) {
      window.location.href = "tel://" + tel;
    },

    // 【请求】获取线索详情
    getClueInfo() {
      let clueId = this.clueId;
      getClueInfo(clueId).then((res) => {
        if (res.success) {
          this.clueInfo = res.data;
        }
      });
    },

    // 【请求】获取线索跟进详情
    getTimelineDtoList() {
      let clueId = this.clueId;
      getTimelineDtoList(clueId).then((res) => {
        if (res.success) {
          this.timelineDtoList = res.data.timelineDtoList;
        }
      });
    },

    // 【监听】客户转交
    onChange() {
      this.$router.push("/clue/clueChange?clueId=" + this.clueId);
    },

    // 【监听】客户编辑
    onEdit() {
      this.$router.push("/clue/clueEdit?clueId=" + this.clueId);
    },

    // 【监听】客户跟进
    onFollow() {
      this.$router.push("/clue/clueFollow?clueId=" + this.clueId);
    },

  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/theme';

.clue-info {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
}


.foot {
  padding: .1rem;
  display: flex;
  justify-content: space-around;

  .size {
    width: 100%;
    height: .36rem;
    margin: 0 .1rem;
  }

  .orange {
    color: #e6a23c;
    background: #fdf6ec;
    border-color: #f5dab1;
  }

  .blue {
    color: #409eff;
    background: #ecf5ff;
    border-color: #b3d8ff;
  }

  .green {
    color: #67c23a;
    background: #f0f9eb;
    border-color: #c2e7b0;
  }
}

.text-item {
  border-bottom: .15rem #f1f2f6 solid;

  h1 {
    padding: .1rem;
    display: flex;
    align-items: center;
  }

  .text-icon {
    display: inline-block;
    background: #1989fa;
    width: 0.04rem;
    border-radius: 0 0.04rem 0.04rem 0;
    margin-right: 0.1rem;
    height: 0.16rem;
  }

  .text-content {
    padding: .1rem;
    font-size: .14rem;

    p {
      margin: .05rem 0;
    }
  }
}


.follow-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .1rem;
}
</style>